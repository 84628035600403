<template>
  <div class="ct-home margin-content page-library">
    <div class="ct-home-filter">
      <div class="container">
        <div class="d-flex align-items-center version-pc">
          <div class="filter-btn">
            <button
              class="btn btn-info"
              @click="showHideModal"
              name="search_library_button"
            >
              <b-icon class="filter-icon mr-3" icon="search" />絞り込み検索
            </button>
          </div>
          <div class="ct-home-link mx-4">
            <div @click="chooseFilterOut(dataLike)">＃お気に入り追加済</div>
          </div>
          <div class="ct-home-link mx-4">
            <div @click="chooseFilterOut(dataHistory)">
              ＃最近学習したコンテンツ
            </div>
          </div>
        </div>
        <div class="custom-sp version-sp mb-3">
          <button class="btn btn-secondary" @click="showHideModal">
            絞り込み
          </button>
          <button class="btn btn-secondary" @click="chooseFilterOut(dataLike)">
            お気に入り
          </button>
          <button
            class="btn btn-secondary"
            @click="chooseFilterOut(dataHistory)"
          >
            最近閲覧
          </button>
          <button class="btn btn-secondary" @click="chooseFilterOut(dataBuy)">
            最近購入
          </button>
        </div>
        <div class="ct-home-allOption d-flex flex-wrap align-items-center mt-4">
          <div
            v-for="(value, index) in filterSelect"
            class="allOption-item mb-4 mr-4 d-flex align-items-center"
            :key="index + value.text"
          >
            <div class="allOption-content mr-2" v-if="value.text">
              {{ value.text }}
            </div>
            <div
              v-if="value.text"
              class="allOption-close"
              @click="removeFilter(index)"
            >
              <font-awesome-icon :icon="['fas', 'times']" />
            </div>
          </div>
          <div
            v-for="(value, index) in categoryDisplay"
            class="allOption-item mb-4 mr-4 d-flex align-items-center"
            :key="index + value.text"
          >
            <div class="allOption-content mr-2">{{ value.text }}</div>
            <div class="allOption-close" @click="removeFilter(index)">
              <font-awesome-icon :icon="['fas', 'times']" />
            </div>
          </div>
          <div
            v-for="(value, index) in authorDisplay"
            class="allOption-item mb-4 mr-4 d-flex align-items-center"
            :key="index + value.text"
          >
            <div class="allOption-content mr-2">{{ value.text }}</div>
            <div class="allOption-close" @click="removeFilter(index)">
              <font-awesome-icon :icon="['fas', 'times']" />
            </div>
          </div>
        </div>
        <div v-if="isOpenFilter" class="ct-home-modalFilter">
          <div
            class="position-fixed modalFilter-backdrop"
            @click="showHideModal"
          />
          <div class="position-fixed modalFilter-content">
            <div
              v-if="modalOpen > 1"
              class="modalFilter-return"
              @click="returnModal"
            >
              <b-icon icon="arrow-left" scale="1" />
            </div>
            <div class="modalFilter-close" @click="showHideModal">
              <font-awesome-icon :icon="['fas', 'times']" />
            </div>
            <div class="modalFilter-header text-center">{{ modalTitle }}</div>
            <div style="max-height: 70vh; overflow: auto">
              <div
                v-for="(value, index) in modalContent"
                class="modalFilter-item position-relative"
                :key="index"
                @click="chooseFilter(value)"
              >
                <div>{{ value.text }}</div>
                <div
                  class="modalFilter-itemIcon position-absolute"
                  v-bind:class="{ 'modalFilter-check': !value.open }"
                >
                  <font-awesome-icon
                    v-if="value.open"
                    icon="fas fa-angle-right"
                  />
                  <b-icon
                    v-else-if="
                      (modalOpen === 1 &&
                        filterSelect.find((item) => item.id === value.id)) ||
                      (modalOpen === 2 &&
                        categorySelect.find((item) => item.id === value.id)) ||
                      (modalOpen === 3 &&
                        authorSelect.find((item) => item.id === value.id))
                    "
                    icon="check"
                    scale="2"
                  />
                </div>
              </div>
              <div v-if="modalOpen === 1" class="modalFilter-search mt-3">
                <label>キーワード</label>
                <div class="ct-home-search d-flex align-items-center">
                  <b-form-input
                    class="search-input"
                    v-model="text"
                    placeholder="商品名・目次で検索・著者名・カテゴリ名"
                  />
                  <div class="search-icon" @click="chooseFilter({ text })">
                    <b-icon icon="search" />
                  </div>
                </div>
              </div>
              <!-- <div v-if="listLibrary && listLibrary.length > 0">
                <div
                  v-if="
                    modalOpen === 2 &&
                    listLibraryCategory.current_page <
                      listLibraryCategory.last_page
                  "
                  class="d-flex justify-content-center align-items-center pb-5"
                >
                  <b-button class="btn-info" @click="seeMoreCategory"
                    >一覧で表示するxx</b-button
                  >
                </div>
                <div
                  v-if="
                    modalOpen === 3 &&
                    listLibraryAuthor.current_page < listLibraryAuthor.last_page
                  "
                  class="d-flex justify-content-center align-items-center pb-5"
                >
                  <b-button class="btn-info" @click="seeMoreAuthor"
                    >一覧で表示する</b-button
                  >
                </div>
              </div> -->
              <div
                v-if="modalOpen === 2 || modalOpen === 3"
                class="pb-4 modalFilter-btn d-flex justify-content-center"
              >
                <b-button class="btn-info mt-3" @click="searchLibrary(null)"
                  >検索する</b-button
                >
              </div>
              <div
                v-else
                class="pb-4 modalFilter-btn d-flex justify-content-center"
              >
                <b-button class="btn-info mt-3" @click="searchLibrary({ text })"
                  >検索する</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ct-home-content px-3">
      <div class="container" v-if="listLibrary && listLibrary.length > 0">
        <b-row>
          <b-col
            v-for="(value, index) in listLibrary"
            class="col-6"
            cols="4"
            sm="4"
            :key="index"
            style="padding: 0 2%; margin-bottom: 4%"
          >
            <div @click="goToDetailContent(value.id)" class="ct-home-card">
              <font-awesome-icon
                v-if="value.is_favorite"
                class="icon-favorite"
                :icon="['fas', 'bookmark']"
                size="3x"
              ></font-awesome-icon>
              <div
                class="card-img"
                :class="{
                  'd-flex  align-items-center justify-content-center no-img':
                    !value.image_url,
                }"
                style="background: #0376bb; border-radius: inherit"
              >
                <img
                  v-if="value.image_url"
                  :src="`${urlBackend}/storage/${value.image_url}`"
                  alt=""
                />
                <div class="h6 m-0" v-else>
                  <span
                    style="
                      text-align: center;
                      margin: 0;
                      white-space: normal;
                      overflow: hidden;
                      display: -webkit-box;
                      -webkit-line-clamp: 3;
                      -webkit-box-orient: vertical;
                    "
                  >
                    {{ value.title }}
                  </span>
                </div>
              </div>
              <div class="title-content-widget">
                <div class="card-name">
                  {{ value.title }}
                </div>
              </div>
              <div
                class="card-desc"
                @click="goToDetailAuthor(value.author_id)"
                v-on:click.stop
              >
                <div class="card-desc-column1 d-flex align-items-center">
                  <div class="card-avatar">
                    <img
                      :src="
                        value.avatar
                          ? `${urlBackend}/${value.avatar}`
                          : image_url_default
                      "
                      alt=""
                    />
                  </div>
                  <div class="card-nickName ml-1 text-dark">
                    <p>{{ value.last_name + " " + value.first_name }}</p>
                  </div>
                </div>
                <div class="card-desc-column2 card-btn">
                  <button
                    class="card-btn-pc"
                    :name="
                      value.is_favorite
                        ? `unsub_favorite_` + `${value.id}`
                        : `add_favorite_` + `${value.id}`
                    "
                    :class="
                      value.is_favorite ? 'btn-danger btn' : 'btn btn-success'
                    "
                    @click="addFavorites(value.id, index)"
                    v-on:click.stop
                    style="
                      padding: 0 4px;
                      font-size: 12px;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                    "
                  >
                    <p>
                      {{ value.is_favorite ? "お気に入り解除" : "お気に入り" }}
                    </p>
                  </button>
                  <div
                    class="card-btn-sp"
                    :name="
                      value.is_favorite
                        ? `unsub_favorite_` + `${value.id}`
                        : `add_favorite_` + `${value.id}`
                    "
                    @click="addFavorites(value.id, index)"
                    v-on:click.stop
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-heart-fill"
                      viewBox="0 0 16 16"
                      :style="{
                        color: value.is_favorite ? '#f89b9b' : '#DEE2E6',
                      }"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                      />
                    </svg>
                    <!-- <i
                      class="card-btn-sp"
                      :style="{
                        color: value.is_favorite ? '#f89b9b' : '#6F2CF2',
                      }"
                      class="bi bi-heart-fill"
                    ></i> -->
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="container mt-3">{{ messageEmpty }}</div>
    </div>
    <div
      class="d-flex justify-content-center align-items-center"
      style="padding: 0 0 5%"
    >
      <b-button
        class="btn-info"
        v-if="
          dataLibraries
            ? dataLibraries.last_page !== dataLibraries.current_page
            : dataLibrary
            ? dataLibrary.last_page !== dataLibrary.current_page
            : ''
        "
        @click="seeMoreContent()"
        >続きを見る<b-spinner v-if="isShowLoading" small></b-spinner
      ></b-button>
    </div>
    <!-- <div
      v-for="(value, index) in listLibrary"
      :key="index"
      @click="goToDetailContent(value.id)"
      class="content-box ht-card"
    >
      <div class="version-sp content-cart-sp">
        <div class="d-flex info">
          <div
            class="image"
            :class="{
              'no-img align-items-center justify-content-center':
                !value.image_url,
            }"
          >
            <font-awesome-icon
              v-if="value.is_favorite"
              class="icon-favorite"
              :icon="['fas', 'bookmark']"
            ></font-awesome-icon>
            <img
              v-if="value.image_url"
              :src="`${urlBackend}/storage/${value.image_url}`"
              alt=""
            />
            <div class="h5 text-dark" v-else>
              {{ value.title }}
            </div>
          </div>
          <div class="info-content">
            <div class="card-name px-3 pt-1 pb-1 font-title-content-cus">
              {{ value.title }}
            </div>
            <div class="d-flex align-items-center pb-1 pl-3 author">
              <div
                class="card-avatar"
                style="min-width: 20px; width: 20px; height: 20px"
              >
                <img
                  :src="
                    value.avatar
                      ? `${urlBackend}/${value.avatar}`
                      : image_url_default
                  "
                  alt=""
                />
              </div>
              <div class="card-nickName ml-2 text-dark">
                {{ value.last_name + " " + value.first_name }}
              </div>
            </div>
            <div class="card-btn pb-3 pl-3">
              <b-button
                :name="`add_favorite_` + `${value.id}`"
                class="mt-2"
                style="min-width: 125px"
                :class="value.is_favorite ? 'btn-danger' : 'btn-success'"
                @click="addFavorites(value.id, index)"
                v-on:click.stop
              >
                <span v-if="value.is_favorite">お気に入り解除</span>
                <span v-else>お気に入り追加</span>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-center version-sp"
      style="padding: 5% 0"
    >
      <div class="mt-3">
        {{ messageEmpty }}
      </div>
      <b-button class="btn-info" @click="seeMoreContent()">
        {{ isSeeMore == true ? "続きを見る" : "一覧で表示する" }}
        <b-spinner v-if="isShowLoading" small></b-spinner>
      </b-button>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import { VueEditor } from "vue2-editor";
import image_url_default from "@/assets/img/content-default.png";
import { Constants } from "../../utils/constants";
// import PopUp from "@/views/default/PopUp";
import { Api } from "../../utils/http-common";
import { Urls } from "../../utils/urls";

export default {
  name: "Library",
  components: {
    // PopUp,
  },
  data() {
    return {
      urlBackend: Constants.URL_BE,
      test: `<p><span style="background-color: rgb(0, 0, 0);text-align: center;">abc</span></p>`,
      image_url_default,
      text: "",
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      isOpenFilter: false,
      modalOpen: 1,
      modalTitle: "絞り込み検索",
      modalContent: [],
      dataLike: {
        text: "お気に入り",
        open: false,
        id: "favorite",
      },
      dataHistory: {
        text: "最近閲覧",
        open: false,
        id: "view",
      },
      dataBuy: {
        text: "最近購入",
        open: false,
        id: "buy",
      },
      modalContent1: [
        {
          text: "カテゴリ",
          open: true,
          openId: 2,
        },
        {
          text: "著者名",
          open: true,
          openId: 3,
        },
        {
          text: "お気に入り追加済",
          open: false,
          id: "favorite",
        },
        {
          text: "最近学習したコンテンツ",
          open: false,
          id: "view",
        },
        {
          text: "最近購入したコンテンツ",
          open: false,
          id: "purchase",
        },
      ],
      modalContent2: [],
      modalContent3: [],
      formdata: {
        keyword: "",
        author_id: [],
        category_id: [],
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        is_search_favorite: "",
        is_search_recent_content: "",
        is_search_new_content: "",
        limit: 6,
        page: 1,
      },
      filterSelect: [],
      authorSelect: [],
      categorySelect: [],
      authorDisplay: [],
      categoryDisplay: [],
      isShowModal: true,
      dataLibraries: null,
      isFirstCall: false,
      messageEmpty: "",
      listLibrary: [],
      isSeeMore: false,
      indexSelectFavorite: null,
      listCategory: [],
      listAuthor: [],
      formCategory: {
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        page: 0,
        limit: 0,
      },
      formAuthor: {
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        page: 1,
      },
      isDataAuthor: false,
      isDataContent: false,
      isDataCategory: false,
      isShowLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      dataLibrary: "dataLibrary",
      listLibraryAuthor: "listLibraryAuthor",
      listLibraryCategory: "listLibraryCategory",
      success: "success",
      error: "error",
      message: "message",
      successFavorite: "successFavorite",
      detailShop: "detailShop",
    }),
  },
  watch: {
    listLibraryAuthor(newValue, oldValue) {
      this.isDataAuthor = true;
      // if (this.isDataAuthor && this.isDataCategory && this.isDataContent) {
      //   this.$store.commit("set", ["modalLoadingPage", false]);
      // }
      if (
        newValue &&
        oldValue &&
        JSON.stringify(newValue) == JSON.stringify(oldValue)
      )
        return;
      this.listAuthor = this.listLibraryAuthor.map((item) => {
        return {
          text: item.last_name + " " + item.first_name,
          open: false,
          id: item.id,
          kind: "author",
        };
      });
      this.listAuthor.forEach((element, index) => {
        if (
          JSON.stringify(element) !== JSON.stringify(this.modalContent3[index])
        ) {
          this.modalContent3.push(element);
        }
      });
    },
    listLibraryCategory(newValue, oldValue) {
      this.isDataCategory = true;
      if (this.isDataAuthor && this.isDataCategory && this.isDataContent) {
        this.$store.commit("set", ["modalLoadingPage", false]);
      }
      if (
        newValue &&
        oldValue &&
        JSON.stringify(newValue) == JSON.stringify(oldValue)
      )
        return;
      this.listCategory = this.listLibraryCategory.map((item) => {
        return {
          text: item.category_name,
          open: false,
          id: item.id,
          kind: "category",
        };
      });
      this.listCategory.forEach((element, index) => {
        if (
          JSON.stringify(element) !== JSON.stringify(this.modalContent2[index])
        ) {
          this.modalContent2.push(element);
        }
      });
    },
    dataLibrary() {
      this.isDataContent = true;
      this.$store.commit("set", ["modalLoadingPage", false]);
      this.$store.commit("set", [
        "idModalFixed",
        localStorage.getItem("idModalFixed")
          ? localStorage.getItem("idModalFixed")
          : null,
      ]);
      // if (this.isDataAuthor && this.isDataCategory && this.isDataContent) {
      //   this.$store.commit("set", ["modalLoadingPage", false]);
      // }
      this.messageEmpty = "";
      this.modalOpen = 1;
      if (!this.isSeeMore) this.listLibrary = [];
      this.dataLibraries = this.dataLibrary;
      if (this.dataLibrary.data && this.dataLibrary.data.length > 0) {
        if (this.isSeeMore) {
          this.listLibrary = [...this.listLibrary, ...this.dataLibrary.data];
        } else {
          this.listLibrary = this.dataLibrary.data;
        }
        // change title content
        let baseDefault = this.$root.$refs.baseDefault;
        this.listLibrary.forEach(function (item) {
          item.title = baseDefault.changeSentence(item.title);
        });
        this.isFirstCall = true;
        this.isSeeMore = false;
      } else if (!this.isFirstCall && !this.$route.query.keyword) {
        this.messageEmpty = "コンテンツはまだ所持していません";
        this.isFirstCall = true;
      } else {
        this.listLibrary = [];
        this.messageEmpty = "該当するコンテンツは見つかりませんでした。";
      }
      this.isShowLoading = false;
    },
    successFavorite() {
      if (this.successFavorite) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["successFavorite", false]);
        if (
          this.filterSelect.length > 0 &&
          this.filterSelect[0].id === "favorite"
        ) {
          this.isSeeMore = false;
          if (this.detailShop && this.detailShop.id) {
            this.formdata.shop_id = this.detailShop.id;
            this.getDataLibrary(this.formdata);
          }
        } else {
          if (this.listLibrary.length > 0) {
            this.listLibrary[this.indexSelectFavorite].is_favorite =
              !this.listLibrary[this.indexSelectFavorite].is_favorite;
          }
        }
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
        this.$store.commit("set", ["modalLoadingPage", false]);
      }
    },
    detailShop() {
      if (!this.detailShop && !this.detailShop.id) return;
      this.shop_id = this.detailShop.id;
      this.formdata.shop_id = this.detailShop.id;
      this.formAuthor.shop_id = this.detailShop.id;
      this.formCategory.shop_id = this.detailShop.id;
      this.filterSelect = [];
      if (this.$route.query.keyword) {
        const value = { text: this.$route.query.keyword };
        this.formdata = {
          keyword: this.$route.query.keyword,
          author_id: [],
          category_id: [],
          shop_id: this.shop_id,
          is_search_favorite: "",
          is_search_recent_content: "",
          is_search_new_content: "",
          limit: 9,
          page: 1,
        };
        this.filterSelect.push(value);
        this.isSeeMore = false;
        this.getDataLibrary(this.formdata);
      } else {
        this.isSeeMore = false;
        this.getDataLibrary(this.formdata);
      }
      this.getListLibraryAuthor(this.formAuthor);
      this.getListLibraryCategory(this.formCategory);
    },
  },
  created() {
    this.$store.commit("set", ["modalLoadingPage", true]);
    this.$store.commit("set", ["listLibraryCategory", []]);
    this.$store.commit("set", ["listLibraryAuthor", []]);
    if (this.$route.params.message) {
      this.$toasted.success(this.$route.params.message);
    }
    if (this.detailShop && this.detailShop.id) {
      this.shop_id = this.detailShop.id;
      this.formdata.shop_id = this.detailShop.id;
      this.formAuthor.shop_id = this.detailShop.id;
      this.formCategory.shop_id = this.detailShop.id;
      if (this.$route.query.keyword || this.$route.query.categoryId) {
        const value = { text: this.$route.query.keyword };
        const categoryId = this.$route.query.categoryId
          ? [Number(this.$route.query.categoryId)]
          : [];
        this.formdata = {
          keyword: this.$route.query.keyword,
          author_id: [],
          category_id: categoryId,
          shop_id: this.shop_id,
          is_search_favorite: "",
          is_search_recent_content: "",
          is_search_new_content: "",
          limit: 9,
          page: 1,
        };
        this.filterSelect.push(value);
        this.isSeeMore = false;
        this.getDataLibrary(this.formdata);
      } else {
        this.isSeeMore = false;
        this.getDataLibrary(this.formdata);
      }
      this.getListLibraryAuthor(this.formAuthor);
      this.getListLibraryCategory(this.formCategory);
    }
  },
  methods: {
    ...mapActions({
      addFavorite: "addFavorite",
      getDataLibrary: "getDataLibrary",
      getListLibraryAuthor: "getListLibraryAuthor",
      getListLibraryCategory: "getListLibraryCategory",
    }),
    showHideModal() {
      this.isOpenFilter = !this.isOpenFilter;
      if (this.isOpenFilter) {
        this.modalContent = this.modalContent1;
        this.modalTitle = "絞り込み検索";
      }
      this.modalOpen = 1;
    },
    returnModal() {
      this.modalOpen = 1;
      this.modalContent = this.modalContent1;
      this.modalTitle = "絞り込み検索";
    },
    chooseFilter(value) {
      this.dataLibraries = null;
      this.text = "";
      if (this.modalOpen === 1) {
        if (value.open) {
          this.modalContent = this["modalContent" + value.openId];
          this.modalOpen = value.openId;
          this.modalTitle = value.text;
        } else {
          this.formdata = {
            keyword: "",
            author_id: [],
            category_id: [],
            shop_id: this.shop_id,
            is_search_favorite: "",
            is_search_recent_content: "",
            is_search_new_content: "",
            limit: 9,
            page: 1,
          };
          this.authorSelect = [];
          this.categorySelect = [];
          this.authorDisplay = [];
          this.categoryDisplay = [];
          if (value.id === "favorite") {
            this.formdata.is_search_favorite = true;
            this.isSeeMore = false;
            this.getDataLibrary(this.formdata);
          } else if (value.id === "view") {
            this.formdata.is_search_recent_content = true;
            this.isSeeMore = false;
            this.getDataLibrary(this.formdata);
          } else if (value.id === "purchase") {
            this.formdata.is_search_new_content = true;
            this.isSeeMore = false;
            this.getDataLibrary(this.formdata);
          } else if (!value.id) {
            this.formdata.keyword = value.text;
            this.isSeeMore = false;
            this.getDataLibrary(this.formdata);
          }
          this.filterSelect = [];
          this.filterSelect.push(value);
          this.isOpenFilter = false;
          this.modalOpen = 0;
        }
      } else {
        if (this.modalOpen === 2) {
          if (this.categorySelect.find((item) => item.id === value.id)) {
            this.formdata.category_id.splice(
              this.formdata.category_id.indexOf(value.id),
              1
            );
            this.categorySelect.splice(this.categorySelect.indexOf(value), 1);
          } else {
            this.formdata.category_id.push(value.id);
            this.categorySelect.push(value);
          }
        } else if (this.modalOpen === 3) {
          if (this.authorSelect.find((item) => item.id === value.id)) {
            this.formdata.author_id.splice(
              this.formdata.author_id.indexOf(value.id),
              1
            );
            this.authorSelect.splice(this.authorSelect.indexOf(value), 1);
          } else {
            this.formdata.author_id.push(value.id);
            this.authorSelect.push(value);
          }
        }
      }
    },
    searchLibrary(value) {
      this.dataLibraries = null;
      this.listLibrary = [];
      if (value) {
        this.formdata.keyword = value.text;
        this.filterSelect = [];
        this.filterSelect.push(value);
      } else {
        this.formdata.keyword = "";
        this.filterSelect = [];
      }
      this.formdata.is_search_favorite = "";
      this.formdata.is_search_recent_content = "";
      this.formdata.is_search_new_content = "";
      this.authorDisplay = [];
      this.categoryDisplay = [];
      this.authorSelect.forEach((element) => {
        this.authorDisplay.push(element);
      });
      this.categorySelect.forEach((element) => {
        this.categoryDisplay.push(element);
      });
      // this.isSeeMore = false;
      this.formdata.page = 1;
      this.isOpenFilter = false;
      this.getDataLibrary(this.formdata);
      this.modalOpen = 0;
    },
    removeFilter(index) {
      this.dataLibraries = null;
      this.listLibrary = [];
      this.messageEmpty = "";
      if (this.filterSelect.length > 0) {
        this.filterSelect.splice(index, 1);
      }
      if (this.categoryDisplay.length > 0) {
        this.categoryDisplay.splice(index, 1);
        this.formdata.category_id.splice(index, 1);
        this.categorySelect.splice(index, 1);
      }
      if (this.authorDisplay.length > 0) {
        this.authorDisplay.splice(index, 1);
        this.formdata.author_id.splice(index, 1);
        this.authorSelect.splice(index, 1);
      }
      if (
        this.filterSelect.length === 0 &&
        this.categoryDisplay.length === 0 &&
        this.authorDisplay.length === 0
      ) {
        this.formdata = {
          keyword: "",
          author_id: [],
          category_id: [],
          shop_id: this.shop_id,
          is_search_favorite: "",
          is_search_recent_content: "",
          is_search_new_content: "",
          limit: 9,
          page: 1,
        };
      }
      this.formdata.page = 1;
      this.isSeeMore = false;
      this.getDataLibrary(this.formdata);
    },
    chooseFilterOut(value) {
      this.dataLibraries = null;
      this.listLibrary = [];
      this.formdata = {
        keyword: "",
        author_id: [],
        category_id: [],
        shop_id: this.shop_id,
        is_search_favorite: "",
        is_search_recent_content: "",
        is_search_new_content: "",
        limit: 9,
        page: 1,
      };
      this.authorSelect = [];
      this.categorySelect = [];
      this.authorDisplay = [];
      this.categoryDisplay = [];
      if (value.id === "favorite") {
        this.formdata.is_search_favorite = true;
        this.isSeeMore = false;
        this.getDataLibrary(this.formdata);
      } else if (value.id === "view") {
        this.formdata.is_search_recent_content = true;
        this.isSeeMore = false;
        this.getDataLibrary(this.formdata);
      } else if (value.id === "buy") {
        this.formdata.is_search_new_content = true;
        this.isSeeMore = false;
        this.getDataLibrary(this.formdata);
      }
      this.filterSelect = [value];
    },
    filterContent() {
      this.formdata.page += 1;
      this.isSeeMore = true;
      this.isShowLoading = true;
      this.getDataLibrary(this.formdata);
    },
    seeMoreContent() {
      this.formdata.page += 1;
      this.isSeeMore = true;
      this.isShowLoading = true;
      const api = Api.userRequestServer;
      api
        .post(
          `/${Urls.LIBRARY}/${Urls.FILTER}?${Urls.PAGE}=${this.formdata.page}`,
          this.formdata
        )
        .then((response) => {
          if (response.status == 401) {
            localStorage.removeItem(Constants.TOKEN_USER);
            localStorage.removeItem(Constants.USER_TYPE_USER);
            localStorage.removeItem(Constants.EXPIRES_AT_USER);
            localStorage.removeItem(Constants.USER_ID);
            localStorage.removeItem(Constants.EMAIL_USER);
            localStorage.removeItem("popupSend");
            localStorage.removeItem(Constants.NORMAL_USER_INFO);
            localStorage.removeItem(Constants.IS_REMEMBER_USER);
            this.$router.push({
              name: this.$router.currentRoute.params.shopId
                ? "login shop"
                : "login shop domain",
            });
          }
          const { data } = response;
          if (data.success) {
            if (
              this.isDataAuthor &&
              this.isDataCategory &&
              this.isDataContent
            ) {
              this.$store.commit("set", ["modalLoadingPage", false]);
            }
            this.messageEmpty = "";
            this.modalOpen = 1;
            if (data.data.data && data.data.data.length > 0) {
              data.data.data.forEach((element) => {
                this.listLibrary.push(element);
              });
              this.isFirstCall = true;
              this.dataLibraries = data.data;
            } else if (!this.isFirstCall && !this.$route.query.keyword) {
              this.messageEmpty = "コンテンツはまだ所持していません";
              this.isFirstCall = true;
            } else {
              this.dataLibraries = [];
              this.messageEmpty = "該当するコンテンツは見つかりませんでした。2";
            }
          }
          this.isShowLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isShowLoading = false;
        });
    },
    seeMoreCategory() {
      this.formCategory.page += 1;
      this.getListLibraryCategory(this.formCategory);
    },
    seeMoreAuthor() {
      this.formAuthor.page += 1;
      this.getListLibraryAuthor(this.formCategory);
    },
    async addFavorites(content_id, index) {
      this.indexSelectFavorite = index;
      await this.$store.dispatch("addFavorite", {
        content_id: content_id,
        shop_id: this.shop_id,
      });
      const req = {
        shop_id: this.shop_id,
        title: this.$route.meta.title,
        referrer_url: window.location.origin + this.$route.path,
        url: window.location.origin + this.$route.path,
        id_button: window.document.activeElement.name,
      };
      this.$store.dispatch("logActive", req);
    },
    goToDetailAuthor(author_id) {
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "author detail",
          params: {
            shopId: this.shop_id,
            id: author_id,
          },
        });
      } else {
        this.$router.push({
          name: "author detail domain",
          params: {
            id: author_id,
          },
        });
      }
    },
    goToDetailContent(content_id) {
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "DetailContentLibrary",
          params: {
            shopId: this.shop_id,
            idContent: content_id,
          },
          query: { keyword: this.formdata.keyword },
        });
      } else {
        this.$router.push({
          name: "DetailContentLibrary domain",
          params: {
            idContent: content_id,
          },
          query: { keyword: this.formdata.keyword },
        });
      }
    },
    // hideModalPop(e) {
    //   this.isShowModal = e;
    // },
  },
};
</script>

<style lang="scss" scoped>
.ct-home {
  &-tabs {
    height: 100px;
    background: #d5d5d5;
  }
  &-search {
    position: relative;
    width: 400px;
    height: 100%;
    input {
      width: 100%;
    }
    .search {
      &-input {
        padding-right: 35px;
      }
      &-icon {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }
  &-tab {
    .custom-tab {
      width: 120px;
      height: 60px;
      border-radius: 10px 10px 0 0;
      &.active {
        background: #ebedef;
      }
    }
  }
  &-filter {
    padding: 30px 15px;
    .filter-btn {
      button {
        padding: 10px 20px;
        border-radius: 10px;
        // @media (max-width: 767px) {
        //   padding: 0 10px;
        // }
      }
    }
    .filter-icon {
      font-size: 24px;
    }
  }
  &-link {
    font-size: 18px;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
    // @media (max-width: 767px) {
    //   font-size: 12px;
    //   margin: 0 10px !important;
    // }
  }
  &-allOption {
    // height: 98px;
    .allOption {
      &-close {
        width: 25px;
        height: 25px;
        background: rgb(110, 110, 110);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
      }
    }
  }
  &-modalFilter {
    .modalFilter {
      &-backdrop {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(5px);
        background-color: #0000008f;
        z-index: 10;
      }
      &-content {
        top: 50%;
        left: 50%;
        max-width: calc(100% - 20px);
        margin: auto;
        transform: translate(-50%, -50%);
        background: #fff;
        z-index: 11;
        border: 1px solid #b5b5b5;
        border-radius: 10px;
        // @media (max-width: 767px) {
        //   top: 40%;
        // }
      }
      &-header {
        padding: 25px 0;
        border-bottom: 1px solid #b5b5b5;
        font-size: 20px;
      }
      &-item {
        padding: 25px 20px;
        width: 700px;
        max-width: 100%;
        cursor: pointer;
        &:hover {
          background: #e3e3e3;
        }
      }
      &-search {
        padding: 20px;
        padding-top: 0;
        .ct-home-search {
          width: 100%;
          input {
            height: 50px;
          }
          .search-icon {
            right: 10px;
            font-size: 20px;
          }
        }
      }
      &-close {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 25px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      &-return {
        position: absolute;
        top: 15px;
        left: 15px;
        font-size: 25px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      &-itemIcon {
        top: 50%;
        right: 25px;
        transform: translateY(-50%);
        font-size: 20px;
      }
      &-check {
        color: #46c000;
      }
      &-success {
        color: #fff;
        background: #d3421e;
        border: unset;
      }
      &-btn {
        button {
          width: calc((100% - 20px) / 2);
          height: 40px;
        }
      }
    }
  }
  &-card {
    position: relative;
    .icon-favorite {
      position: absolute;
      left: 0;
      top: 0;
      color: red;
    }
    a:hover {
      text-decoration: none;
    }
    .card {
      &-img {
        background-color: #dee9f0;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease 0s;
        aspect-ratio: 1/1;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      &-avatar {
        width: 30px;
        min-width: 30px;
        height: 30px;
        border-radius: 100%;
        overflow: hidden;
        transition: all 0.3s ease 0s;
        aspect-ratio: 1/1;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      &-btn {
        display: flex;
        justify-content: center;
      }
    }
  }
}
.content-cart-sp {
  box-shadow: 0px 3px 6px #7681929c;
  width: 100%;
  height: auto;
  border-radius: 10px;
  padding: 20px;
  .info {
    align-items: center;
    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(222, 233, 240);
      height: 100px;
      min-width: 100px;
      max-width: 100px;
      position: relative;
      img {
        height: 100%;
        width: 100%;
        max-height: 100%;
        max-width: 100%;
      }
      .icon-favorite {
        position: absolute;
        left: 0;
        top: 0;
        color: red;
        height: 1rem;
      }
    }
  }
}
.ct-home-card {
  box-shadow: -5px -5px 10px #a3abaf, 5px 5px 10px #a3abaf;
  border: unset !important;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Avenir,
    "Open Sans", "Helvetica Neue", Helvetica, Arial, Verdana, Roboto,
    "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "Meiryo UI", "メイリオ",
    Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
.font-title-content {
  height: 34.5px;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
}
.font-title-content-cus {
  height: 34.5px;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  word-break: break-word;
  width: auto;
  height: auto;
  padding-right: 0px !important;
}
.title-content-widget {
  width: unset !important;
  height: unset;
}
.custom-sp {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-flow: row;
}

.card-nickName {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  min-width: 40px;
}

.card-desc {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 5px 5px;
  padding: 0;

  &-column1,
  &-column2 {
    min-width: 0;
    flex-basis: 50%;

    & p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0;
    }
  }
}

.card-name {
  overflow: hidden;
  font-size: 12px;
  line-height: 16px;
  height: 48px;
  margin: 10px 5px 5px;
  padding: 0;
}

.card-btn-sp {
  display: none;
}

@media (max-width: 450px) {
  .card-avatar {
    min-width: 20px !important;
    width: 20px !important;
    height: 20px !important;

    & img {
      vertical-align: unset !important;
    }
  }

  .card-btn-pc {
    display: none;
  }

  .card-btn-sp {
    display: block;
  }

  .card-desc {
    &-column1 {
      min-width: 0;
      flex-basis: 80%;
    }
    &-column2 {
      min-width: 0;
      flex-basis: 20%;
    }
  }
}
</style>
